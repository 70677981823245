import React, {useState } from 'react';
import Button from "../../UI/Button";
import classes from "./FeedNewComment.module.css";
import Card from "../../UI/Card";
import {useDispatch, useSelector} from "react-redux";
import { postFetchCommentary } from '../../../store/actions/commentary-actions';
  

const FeedNewComment = ({idPostsUser}) => {

   const authentification = useSelector((state) => state.authentification.dataResponse);

    const dispatch = useDispatch();


    //pour stocker le contenu du message
    const [message, setMessage] = useState(null);

    
    //l'information du click sur le bouton OK et sur le bouton envoyer
    const [clickSend, setClickSend] = useState(false);
         

    //lorsque l'on appuie sur le bouton envoyer du formulaire
    const SubmitHandler = (e) => {
        //pour ne pas recharger la page
        e.preventDefault();


        const data  = {
             
                userId: authentification.userId,
                idPost: idPostsUser,
                message: message,
                
          };


      //la requête POST avec fetch pour envoyer les données au backend
     //l'exécution de la fonction(requête POST vers le serveur)
             dispatch(postFetchCommentary(authentification.userId, authentification.token, data));
              setMessage("");
              setClickSend(false);
            
        }; 


  

  return (
    <section className={classes.feedNewComment}>
      <Card className={classes.card}>
        <form onSubmit={SubmitHandler}>
            <label htmlFor='message'>Commenter</label>
            <textarea
             id="message" 
             name="message"
             placeholder="Votre commentaire..."
             value={message ? message : ""}
             onChange={(event) => setMessage(event.target.value)}
             />  


             {/***Bouton pour envoyer les données vers le serveur sql */}
             <Button
                type={"submit"}
                onClick={() => setClickSend(true)}
                className={classes}
                id={!message && clickSend && classes["red"]}
                >
                   Envoyer
             </Button>
            


             {/**message d'avertissement pour l'utilisateur */}
             {!message && 
               clickSend &&
              <p id={classes["messageEmpty"]}>Le message ne peut pas être vide</p>}

              

        </form> 
      </Card>
    </section>
  )
}

export default FeedNewComment;
