import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FeedIdentifierCreatorForSearchResult from './FeedIdentifierCreatorForSearchResult';
import Card from '../../UI/Card';
import FeedPhotoUser from '../FeedPhotoUser';
import { useSelector } from 'react-redux';
import useHttp from '../../../hooks/use-http';
import classes from "./Results.module.css"
  


const Result = () => { 
    
   const authentification = useSelector((state) => state.authentification.dataResponse);

   
   const [messages, setMessages] = useState([]);

   const [mounted, setMounted] = useState(true);

 //CUSTOM HOOK HTTP GET
 const {sendRequest: fetchGetMessageHandler} = useHttp();
       
  


   //pour aller chercher les posts sur la base de données
 useEffect(() =>  {
   //objet de configuration de la requête du custom hook
    const requestConfig =  {
         url: `${process.env.REACT_APP_GRAFFITI_API_URL}/api/posts?userId=${authentification.userId}`,
         method: "GET",
         headers: {   
            Authorization: `Bearer ${authentification.token}`,
         },
    }
   
    
    if(mounted) {
      
         authentification.userId && fetchGetMessageHandler(requestConfig, (applyData) => {
             setMessages(applyData);
         
         }) ;  
       
 
    }
 
    //fonction de nettoyage
   return () => {
      setMounted(true);
     };    
     
  },[
     
     authentification.userId, 
     fetchGetMessageHandler,
     mounted, 
     authentification.token]);
 
 

const orderDisplayMessage = messages && messages.sort((a, b) => {
   return(
     b.fiche_user_userId - a.fiche_user_userId
    )
 });



  return (
    <div>
          <h3 className={classes.resultsTitle}>Resultat de votre recherche:</h3>
     
     <div className={classes.resultsContainer} >  
         
            { orderDisplayMessage &&
               orderDisplayMessage.map((message) => {
                  return(
            <section key={message.fiche_user_userId}> 
                <Card 
                   className={classes.card} 
                    >
                     <Link
                       // to={`/fiche_utilisateur/read/${message.fiche_user_userId}`} 
                       to={`/user/read/${message.fiche_user_userId}`} 

                        >
                      <FeedIdentifierCreatorForSearchResult 
                        user_prenom={message.fiche_user_prenom}
                        />
                     </Link>
                  

                  <div className={classes.photoMessage}>
                     <Link
                        //to={`/fiche_utilisateur/read/${message.fiche_user_userId}`}
                        to={`/user/read/${message.fiche_user_userId}`} 

                       >
                        <FeedPhotoUser 
                            photoProfilUrl={message.fiche_user_photoProfilUrl}
                         />
                     </Link>
                     </div>

                   </Card>
             </section>
                  )
               })
            }

        



 
</div>
    </div>
  )              
}

export default Result;
