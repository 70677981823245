import React from 'react';
import classes from "./FeedPhotoUser.module.css";
import emptyPortrait from "../../assets/images/photo-avatar.png";



const FeedPhotoUser = ({photoProfilUrl}) => {
  return (
    <div className={classes.feedPhotoUser}>
       <img crossOrigin="anonymous" src={photoProfilUrl ? photoProfilUrl : emptyPortrait} alt="photo_de_profil"/>
    </div>
  )
}

export default FeedPhotoUser
