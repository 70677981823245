import React, {useState } from 'react';
import classes from "./FeedButton.module.css";
import Button from "../UI/Button";
import ConfirmationModal from "../UI/ConfirmationModal";
import FeedBadge from './Comments/FeedBadge';
import useHttp from '../../hooks/use-http';
import { useSelector } from 'react-redux';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import ModeCommentRoundedIcon from '@mui/icons-material/ModeCommentRounded'; 


const FeedButton = ({
        userIdToken,
        token, 
        idPostsUser, 
        userIdPost, 
        onUpdateDelete,
        onModificationMessage,
        isUpdatingPost,
        onSendMessage,
        onCommentsDisplayPost,
         
      }) => {

         const [confimationModal, setConfimationModal] = useState(null);

         const authentification = useSelector((state) => state.authentification.dataResponse);

         const {sendRequest: fetchDeletePostFeedHandler} = useHttp();

  //pour supprimer un post dans le feed
  const deletePost = () => {
   
    //la requête DELETE(custom hook use-http) à envoyer au backend pour supprimer un post dans le feed
    //objet de configuration 
    const requestConfig = {
       url: `${process.env.REACT_APP_GRAFFITI_API_URL}/api/posts/${idPostsUser}?userId=${userIdToken}`,
       method: "DELETE",
       headers: {
         Authorization: `Bearer ${token}`,  
       }

    };

         
       fetchDeletePostFeedHandler(requestConfig, () => {
           onUpdateDelete(idPostsUser);
       });

    };


    
    //modale de confirmation pour supprimer le compte
    const confimationModalHandler = () => {
      setConfimationModal({
        title: "Confirmez la suppression du message",
        message: "Cette suppression sera définitive"

      });
    };



    //gestion du bouton envoyer
    const modificationOnePost = idPostsUser === (isUpdatingPost && isUpdatingPost.postToEdit);
   
    



  return ( 
    <div className={classes.feedButton}>
       
       {/**Bouton Commentaire - pour afficher les commentaires sous le post */}
       {!modificationOnePost &&
         <div className={classes.commentButton}>
        <Button 
         id={idPostsUser} 
         onClick={onCommentsDisplayPost}
          > 
          {/*afficher le badge qui indique le nombre de commentaires*/}
          <FeedBadge 
              userIdToken={userIdToken}
              token={token} 
              idPostsUser={idPostsUser}
             />
            Commentaires
            
          </Button> 
       </div>
        }
     
     
 {/******************************************************************** */}
              
          <> 
         
          {/**bouton modifier */}
            {userIdToken === userIdPost && !isUpdatingPost && 
             <div className={classes.modifyPost}>
              <Button id={idPostsUser} 
                onClick={onModificationMessage}>
                  Modifier
              </Button> 
             </div>}

  
          



            {/***Bouton envoyer */}
            {userIdToken === 
             userIdPost &&
             modificationOnePost &&
             <div className={classes.sendPost}>
               <Button 
                  id={idPostsUser} 
                  onClick={onSendMessage}>
                  Envoyer
               </Button> 
               </div>}
 

          {/**bouton supprimer**/}  
           {userIdToken && ( userIdToken == 
              authentification.userId ||
              authentification.admin === 1 ) &&
              !modificationOnePost &&
              <div className={classes.deletePostIcon}> 
                <Button onClick={confimationModalHandler}>
                  {/**Supprimer**/}
                  <DeleteForeverSharpIcon fontSize='small' />
                </Button>
             </div> }



   {/***** **********bouton Supprimer originel***********
           {(userIdToken === 
           userIdPost ||
             authentification.admin === 1 ) &&
             !modificationOnePost && 
              <div className={classes.deletePostIcon}> 
                <Button onClick={confimationModalHandler}>
                  Supprimer
                 </Button>
              </div>}
     *******************************************/}


  
            {
              confimationModal && 
              <ConfirmationModal
                title={confimationModal.title}
                message={confimationModal.message}
                onConfirm={()=>setConfimationModal(null)}
                onConfirmDelete={()=> deletePost()}
               />
               }

          </>
        
    </div>
  )
}

export default FeedButton
