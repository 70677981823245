import React, { useCallback, useEffect, useState } from 'react';
import classes from "./FeedBadgeForLikes.module.css";
import { useSelector } from 'react-redux';


const FeedBadgeForLikes = ({
    idPostsUser, 
    userIdToken, 
    
  }) => {

  const authentification = useSelector((state) => state.authentification.dataResponse);


  const [numberOfLikes, setNumberOfLikes] = useState([]);

   const newLike = useSelector((state) => state.likes);
   const updateDeleteLike = useSelector((state) => state.onUpdateDeleteLike);
   

const fetchGetNumberLikeHandler = useCallback(async () => {
  
//requête pour avoir le nombre de commentaires(les commentaires du post selectionné)
//aller chercher tous les commentaires de la base de données se trouvant dans la table comments_user
const url = `${process.env.REACT_APP_GRAFFITI_API_URL}/api/posts/likes/${idPostsUser}?userId=${userIdToken}`;
   
try {

        const response = await fetch(url, {
                method: "GET",
                headers: {
                   Authorization: `Bearer ${authentification.token}`,
                },
             }
        );

        const dataResponse = await response.json();

          if(response.ok) {
             console.log(dataResponse);
             setNumberOfLikes(dataResponse.results);
          } else {
            console.log(dataResponse);
            throw new Error(dataResponse.error);
          }



    } catch(error) {
       console.log(error);
    }

}, [idPostsUser, authentification.token, userIdToken]);  

useEffect(() => {
   fetchGetNumberLikeHandler();
},[
  newLike, 
  updateDeleteLike, 
  fetchGetNumberLikeHandler]);



  return (
    <div className={classes.feedBadgeForLikes}>
        <div className={classes.numberCircle}>
          {numberOfLikes && numberOfLikes.length}  
        </div>
    </div>
  )
}

export default FeedBadgeForLikes;
