import React from 'react';
import classes from "./FeedIdentifierCreator.module.css";
import dateFormat from "dateformat";



const FeedIdentifierCreator = ({user_prenom, posts_date }) => {

  //modification du format de la date 
  const date = dateFormat(posts_date, "isoDate");
  const time = dateFormat(posts_date, "isoTime");

  return (
    <div className={classes.feedIdentifierCreator}>
         <p>{user_prenom}</p>
         <p>{`${date} - ${time}`}</p>
    </div>
  )   
}

export default FeedIdentifierCreator;
