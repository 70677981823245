import React, { useEffect, useState } from 'react';
import Linkify from "linkify-react";
import classes from "./FeedPosts.module.css";
import useHttp from "../../hooks/use-http";
 


const FeedPosts = ({
       message,
       isUpdatingPost,
       idPostsUser,
       buttonSend,
       userIdPost,
       token,
       onUpdatePostFinish
     }) => {

 
        const [messageTextarea, setMessageTextarea] = useState({
            posts_user_message : message,
        });


        const  {sendRequest: fetchPUTHandler} = useHttp();

        const postToEdit = isUpdatingPost && isUpdatingPost.postToEdit;

        //condition pour afficher l'interface de modification d'un post
        const modificationOnePost = postToEdit === idPostsUser;


        //fonction éxécuté par onChange du textArea du post
        const messageModificationHandler = (event) => {
              
            setMessageTextarea({"posts_user_message": event.target.value});
        };

     
             //requête PUT pour envoyer les données au serveur
            //exécuter la fonction
            useEffect(() => {
             
             
            //objet de configuration PUT(custom hook)
            const requestConfig = {
              url: `${process.env.REACT_APP_GRAFFITI_API_URL}/api/posts/${idPostsUser}?userId=${userIdPost}`,
              method: "PUT",
              headers:  {
                     "Content-Type": "application/json",
                     Authorization: `Bearer ${token}`, 
                         },
              body: messageTextarea,
            };

            //envoyer le message qui a été mis à jour
        if(buttonSend && modificationOnePost ) {


            fetchPUTHandler(requestConfig, () => {
              onUpdatePostFinish();
            });
          
            }; 

              
            }, [ buttonSend, 
                 fetchPUTHandler, 
                 idPostsUser,
                 messageTextarea, 
                 modificationOnePost,
                 token,
                 userIdPost,
                 onUpdatePostFinish]);


  return (
   
   <div className={classes.feedPosts}>
       {
        modificationOnePost ?  ( 
              
             <textarea 
                defaultValue={message}
                onChange={messageModificationHandler}
                />
            
            )
             
            :(
               
                    <Linkify options={{target:"_blank"}}> 
                     <p className={classes.post}> 
                       {message}  
                      </p>
                    </Linkify>
              
            )
        }
    </div>
  )
}

export default FeedPosts;
