import React, {useEffect, useState } from 'react';
import Button from "../UI/Button";
import classes from "./FeedNewPost.module.css";
import Card from "../UI/Card";
import FeedImageUrl from './FeedImageUrl';
import FeedVideoUrl from './FeedVideoUrl';
import useHttp from '../../hooks/use-http';
import {useSelector} from "react-redux";

const FeedNewPost = ({onUpdate}) => {
    
    
    //pour récupérer le TOKEN d'authentification
    const authentification = useSelector((state) => state.authentification.dataResponse); 
 
    const [message, setMessage] = useState(null);

    //récupérer l'url dans l'input - 
    //l'information du click sur le bouton OK et sur le bouton envoyer
    const [urlInput, setUrlInput] = useState(null);    

    const [urlInputVideo, setUrlInputVideo] = useState(null);
    const [clickSend, setClickSend] = useState(false);
    const [displayInput, setDisplayInput] = useState(false);
    const [displayInputVideo, setDisplayInputVideo] = useState(false);
 
    
    
    //la requête POST use-http
    const {sendRequest: fetchPostHandler} = useHttp();
    

    //condition pour envoyer un post, avec ou sans image, vers le serveur
    const conditionSend = (!displayInput && !displayInputVideo && message) ||
                          (displayInput && message && urlInput) ||
                          ( message && displayInputVideo && urlInputVideo);

 
    //pour mettre urlInput à null lorsque l'on ferme le input, ou lorsqu'on y met l'url
    useEffect(() =>  {
      setUrlInput(null);
      setUrlInputVideo(null);
     }, [displayInput, displayInputVideo]);


    //lorsque l'on appuie sur le bouton envoyer du formulaire
    const SubmitHandler = (event) => {
        //pour ne pas recharger la page
        event.preventDefault();

  
        const data  = {
             
          userId: authentification.userId,
           message: message,
           photoUrlLink:  urlInput, 
           videoUrlLink: urlInputVideo,
           
   };

        

  
    
        //la requête POST(custom hook) avec fetch pour envoyer les données au backend
      //objet de configuration de la requête post  
      const requestConfig = {
        
             //L'URL de la route de la WEB API REST du backend
             url: `${process.env.REACT_APP_GRAFFITI_API_URL}/api/posts?userId=${authentification.userId}`, 
             method: "POST",
             headers: {
              "Content-Type" : "application/json",
              Authorization: `Bearer ${authentification.token}`,
               

             },  
            body: data,   
                           
     };   

     
        //l'exécution de la fonction(requête POST vers le serveur)
          conditionSend &&
              fetchPostHandler(requestConfig, () => {
              onUpdate(message);
              setMessage("");
              setUrlInput(null);
              setClickSend(false);
              setDisplayInput(false);
              setUrlInputVideo(null);
              setDisplayInputVideo(false); 
            });
 
    
      
      };
           
      
 
        
         //récupération de l'url de l'image chez l'enfant
         const onUrlImage  = (urlInput) => {
              
             setUrlInput(urlInput);
         };


         //récupération de l'url de la vidéo chez l'enfant
         const onUrlVideo  = (urlInputVideo) => {
               
              setUrlInputVideo(urlInputVideo);
         };



 

  //la gestion du bouton image
  //au clique sur le bouton, l'input s'affiche pour pouvoir y mettre l'url
  const onButtonImageHandler = () => {
    setDisplayInput((prevState) => !prevState);
 };



//la gestion du bouton vidéo
  //au clique sur le bouton, l'input s'affiche pour pouvoir y mettre l'url
  const onButtonVideoHandler = () => {
    setDisplayInputVideo((prevState) => !prevState);
 };



  return (
    <section className={classes.feedNewPost}>
      <Card className={classes.card}>
        <form onSubmit={SubmitHandler}>
            <label htmlFor='message'>Graffer</label>
            <textarea
             id="message" 
             name="message"
             placeholder="Votre message..."
             value={message ? message : ""}
             onChange={(event) => setMessage( event.target.value)}
             />
 
              
           <div className={classes.buttonsMedia}> 
             {/***Bouton pour mettre une URL d'une image  ou photo hébergées sur le web */}
              {!displayInputVideo && 
               <FeedImageUrl 
                    onUrlImage={onUrlImage}
                    onButtonImageHandler={onButtonImageHandler}
                    displayInput={displayInput}
                    
                 />}
 

             {/***Bouton pour mettre une URL d'une vidéo hébergées sur le web */}
             { !displayInput && 
               <FeedVideoUrl 
                    onUrlVideo={onUrlVideo}
                    onButtonVideoHandler={onButtonVideoHandler}
                    displayInputVideo={displayInputVideo}
                 />}
           </div>


             {/***Bouton pour envoyer les données vers le serveur sql */}
             <Button
                type={"submit"}
                onClick={()=> setClickSend(true)}
                className={classes.button}
                id={!conditionSend && clickSend && classes["red"]}
                >
                   Envoyer
             </Button>
            


             {/**message d'avertissement pour l'utilisateur */}
             {!message && 
               clickSend &&
              <p id={classes["messageEmpty"]}>Le champ destiné au message doit être rempli</p>} 

              {/**message d'avertissement pour l'utilisateur */}
              {(
                (displayInput && clickSend && !urlInput) 
               
                 || 
              
               (displayInputVideo && clickSend && !urlInputVideo)
               )  && ( 
             
              <p id={classes["messageEmpty"]}>Le champ URL est vide</p>)}

        </form> 
      </Card>
    </section>
  )
}

export default FeedNewPost;
