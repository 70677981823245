import React, { useState } from "react";
import  classes from "./Search.module.css";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
   
  
    
const Search = () => {
  

 
const navigate = useNavigate();

const [searchValue, setSearchValue] = useState("");
console.log(searchValue);

 
const [searchResults, setSearchResults] = useState([]);
console.log(searchResults);
 
    

///////////////////////////////////////
const change = (event) => {
  
 setSearchValue(event.target.value);
 
 
};

const navigateToResults = () => {
    if(searchValue.length && searchResults) {
       //navigate("resultats_recherche");
       navigate("search_results");

    }
 
};

  return (
    
   <> 
   <div className={classes.feedSearchBar}>
    <div className={classes.searchContainer}>
       <div className={classes.searchInner}> 
        
          <input
            type="text"
            placeholder='Rechercher...'
         
            value={searchValue}
            onChange={change}
           />
           
            
            <button 
                  onClick={navigateToResults}
              >
               <SearchIcon 
              
               />
            </button>
                
           </div>
    
      </div>
   </div>
     <div>
         

     </div>
   </>   
        
        

    
  );
}

export default Search;

