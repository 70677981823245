import React, { useEffect, useState } from 'react';
import classes from "./FeedPostVideo.module.css";
 
//C'est ici que je ferais mon propre lecteur vidéo
const FeedPostVideo = ({videoPost}) => {

  const [idVideo, setIdVideo] = useState(null);

    

  useEffect(() => {
       
    if(videoPost && videoPost.split("=")[1]) {
     
      setIdVideo(videoPost.split("=")[1]);
   
    } else if(videoPost && videoPost.split("/")[3]) {
     
      setIdVideo(videoPost.split("/")[3]);
   
    }else {
     
      //ID vidéo invalide
      setIdVideo(null);
    }

  }, [videoPost]);



  return (
    <section 
       className={classes.feedPostVideo}>
    
    {videoPost && ( 
       
       <div 
          className={classes.container}>

       <iframe 
           className={classes.reactplayer} 
           width = '450px' 
           height = '275px' 
           controls
           title="YouTube video player" 
           frameborder="0" 
           allow="accelerometer; 
           autoplay; clipboard-write; encrypted-media; gyroscope;
           picture-in-picture; web-share"
           allowFullScreen 
           muted
           src={`https://www.youtube.com/embed/${idVideo}`} 
           ></iframe>
                 
     </div>
       
         )}

</section>
  )
}

export default FeedPostVideo;
