import React from 'react';
import classes from "./FeedImageUrl.module.css";
import Button from '../UI/Button';
  
   
 

const FeedImageUrl = ({onUrlImage, displayInput, onButtonImageHandler}) => {
   

    const inputImageHandler = (event) => {
    // onUrlImage(event.target.files[0]); 
    onUrlImage(event.target.value);  
        };
    
    
  
  return (
    <div className={classes.feedImageUrl}>

        {/**Bouton image pour afficher l'input */}
        <Button onClick={onButtonImageHandler}>Image</Button>


        {/**pour mettre l'url */}
        { displayInput &&
           <> 
                <input 
                  type="text"
                  //type="file"
                  placeholder="URL de l'image"
                  onChange={inputImageHandler}
                  accept=".jpeg, .jpg, .png, .gif"
                  crossOrigin="anonymous"

                 />
                  
           </>
         }
    </div>
  )
}

export default FeedImageUrl;