import React, { useState } from "react";
import { Route, Routes  } from 'react-router-dom';
import MainHeader from './components/layout/MainHeader';
import FicheUser from './pages/FicheUser';
import Home from './pages/Home';
import FicheUserDisplayRead from './components/FicheUser/FicheUserDisplayRead';
import { useSelector } from 'react-redux';
import Result from "./components/Feed/Searching/Result";
import FeedNewPost from "./components/Feed/FeedNewPost";
    

const App = () => {
  const [message, setMessage] = useState(null);

  const onUpdate = (message) => {

    const messageUpdate = message;

    setMessage(messageUpdate);

  };

 
  //savoir si l'utilisateur est connecté(identifié)
  const isLoggedIn = useSelector((state) => state.authentification.isLoggedIn);

  //savoir si la fiche utilisateur est présente sur la base de données mysql
  const newFiche = useSelector((state) => state.ficheUser.ficheUserData.newFiche);

  const conditionLinkActive = newFiche;


  return ( 
    
    <> 
        <MainHeader/>
       
       {/**pour le contrôle des URL qui sont entrées manuellement dans le navigateur */}
       
         <Routes>

             {
              
             conditionLinkActive ||
              (!isLoggedIn &&  !conditionLinkActive) ? (
                 <>
                  <Route path="/" element={ <Home />}/>
                  <Route path="/account/:id" element={ <FicheUserDisplayRead/>} /> 
                  <Route path="/user/" element={  <FicheUser />} /> 
                  <Route path="/user/read/:id" element={ <FicheUserDisplayRead  /> }/>
                  <Route path="/search_results" element={ <Result  /> }/>
                  <Route path="*" element={<Home/>}/>
                  <Route path="/publish" element={ <FeedNewPost onUpdate={onUpdate}  /> }/>

                </>
            ) :(
                <>
                  <Route path="/" element={<FicheUser /> }/>
                  <Route path="/user" element={<FicheUser />} />    
                  <Route path="*" element={<FicheUser />}/>
                  <Route path="*" element={<Home/>}/>
                </>

              )

             }

         </Routes> 
         
    </>
  );
}

export default App;
