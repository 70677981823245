import React from 'react';
import classes from "./FeedVideoUrl.module.css";
import Button from '../UI/Button';



const FeedVideoUrl = ({onUrlVideo, displayInputVideo, onButtonVideoHandler}) => {

    
   //remonter l'information de l'url dans le composant parent
   const inputVideoHandler = (event) => {
     onUrlVideo(event.target.value);
   };


  return (
    <div className={classes.feedVideoUrl}>

        {/**Bouton Video pour afficher l'input */}
        <Button onClick={onButtonVideoHandler}>Video</Button>


        {/**pour mettre l'url */}
        { displayInputVideo &&
           <> 
                <input 
                  type="text"
                  placeholder="URL de la vidéo(Youtube)"
                  onChange={inputVideoHandler}
                 />

               
           </>
         }
    </div>
  )
}

export default FeedVideoUrl;