import React, {useEffect, useState } from 'react';
import classes from "./FeedLike.module.css";
import useHttp from '../../hooks/use-http';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FeedBadgeForLikes from './FeedBadgeForLikes';
import { useSelector } from 'react-redux';


const FeedLike = ({token, idPostsUser, userIdToken}) => {

   const authentification = useSelector((state) => state.authentification.dataResponse);

    const [like, setLike] = useState(null);
    
 
    const [reload, setReload] = useState(false);


    const {sendRequest: fetchPUTLikeHandler} = useHttp();
    const {sendRequest: fetchGetLikeHandler} = useHttp();
    const {sendRequest: fetchPOSTLikeHandler} = useHttp();


//quand on clique sur le bouton like du post celui-ci revient à 0(neutre)
const neutralLikeHandler = () => {

 

 if(like && like.length === 0) { 
//la requête POST avec fetch pour créer un like dans la table likes_user
 const data = {
      likes_user_id_posts: idPostsUser,
      likes_user_userId: userIdToken,
      likes_user_like: 1,
   };

    const requestConfig = {
      url:`${process.env.REACT_APP_GRAFFITI_API_URL}/api/posts/likes/?userId=${userIdToken}`,
      method:"POST",
      headers:{
         "Content-Type" : "application/json",
         Authorization: `Bearer ${authentification.token}`, 
      },
      body:data,
    }
    
    fetchPOSTLikeHandler(requestConfig, () => {
      setReload((prevState) => !prevState);
    });

    //_______________________________//
   
   } else if(like && like[0]) {

    //la requête PUT  pour faire passer le like de  1 à 0  ou inversement
    const data = {
         likes_user_like: like[0].likes_user_like,
      };

 //la requête PUT  pour faire passer le like de  1 à 0  ou inversement
 const requestConfig = {
   //aller chercher tous les likes de la base de données se trouvant dans la table likes_user
    url: `${process.env.REACT_APP_GRAFFITI_API_URL}/api/posts/likes/${like[0].id_likes_user}?userId=${userIdToken}`,
   method: "PUT",
              headers: {
                  "Content-Type" : "application/json",
                  Authorization: `Bearer ${authentification.token}`,
              },
              body: data,
         
  };

      
      fetchPUTLikeHandler(requestConfig, () => {
         setReload((prevState) => !prevState);
      });
   
   }

};


//lorsque la ligne like existe sur la base de données - passer le like de 1 à 0 ou inversement
const likeHandler = () => {

   const data = {
      likes_user_like: like[0].likes_user_like,
   };

   //la requête PUT  pour faire passer le like de  1 à 0  ou inversement
  const requestConfig = {
   //aller chercher tous les likes de la base de données se trouvant dans la table likes_user
    url: `${process.env.REACT_APP_GRAFFITI_API_URL}/api/posts/likes/${like[0].id_likes_user}?userId=${userIdToken}`,
   method: "PUT",
              headers: {
                  "Content-Type" : "application/json",
                  Authorization: `Bearer ${authentification.token}`,
              },
              body: data,
         
  };
         
       fetchPUTLikeHandler(requestConfig, () => {
                      setReload((prevState) => !prevState);

      });

};


  useEffect(() => {
   const requestConfig = {
   
      //aller chercher tous les likes de la base de données se trouvant dans la table likes_user
      url: `${process.env.REACT_APP_GRAFFITI_API_URL}/api/posts/likes/${idPostsUser}?userId=${userIdToken}`,
      method: "GET",
                   headers: {
                      Authorization: `Bearer ${authentification.token}`,
                   },
                  };      


     fetchGetLikeHandler(requestConfig, (dataResponse) => {
        setLike(dataResponse);

     });
  }, [reload, fetchGetLikeHandler, authentification.token, idPostsUser, token, userIdToken]);




  return (
    <div className={classes.feedLike}>
         {
         like &&
         like[0] &&
         like[0].likes_user_like ? 
         <p 
          className={classes.heart}
          onClick={likeHandler}>
             <FeedBadgeForLikes
              userIdToken={userIdToken}
              token={token} 
              idPostsUser={idPostsUser}
              
            />
                <FavoriteIcon
                fontSize='small' 
                />
         </p> :
         <p  
           className={classes.heart}
           onClick={neutralLikeHandler}>
            
            <FavoriteBorderIcon
              fontSize='small'
            />
            </p>
         }
    </div>
  )
}

export default FeedLike;
