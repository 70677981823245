import { useSelector } from "react-redux";
import AuthForm from "../components/Auth/AuthForm";
import Feed from "../components/Feed/Feed";
 
 
const Home = () =>{

 
  
  const isLoggedIn = useSelector((state) => state.authentification.isLoggedIn);

    return (
      <section>

        {  
          !isLoggedIn &&
          <AuthForm /> 
        }

        { isLoggedIn && 
          <Feed/> 
        }

        
      </section>
  )
}

 

export default Home;

