//REDUX TOOLKIT STORE
import {configureStore} from "@reduxjs/toolkit";
import commentarySlice from "./slices/commentary-slice";
import authentificationSlice from "./slices/authentification-slice";
import ficheUserSlice from "./slices/ficheUser-slice";

const store = configureStore({
    reducer: {
        commentary: commentarySlice.reducer,
        authentification: authentificationSlice.reducer,
        ficheUser: ficheUserSlice.reducer,
    }
})



export default store;