//ACTIONS ASYNCHRONES (Redux-thunk)

 import { ficheUserActions } from "../slices/ficheUser-slice";



//requête GET pour aller chercher la fiche utilisateur
//également contrôler si la fiche existe ou non
export const getFicheUser = (userId, token, idFiche) => {
   
    return async (dispatch) => {
        //la requête get
        const fetchGetData = async () => { 
           const response = await fetch(
            `${process.env.REACT_APP_GRAFFITI_API_URL}/api/fiche_user/fiche/?userId=${userId}`,
            {
                method : "GET",
                headers: {
                   Authorization : `Bearer ${token} `,
                  
                }, 
            }
           );
           
           const dataResponse =  await response.json();

           if(!response.ok) {
               throw new Error("Problème pour récupérer la fiche user sur le serveur");
           }

           return dataResponse;
        };

        try {
            const ficheUserData = await fetchGetData();
            const transformeData = () => {
            if(ficheUserData && ficheUserData.results.length > 0){

                return {
                    bio: ficheUserData.results[0].fiche_user_bio,
                    photoProfilUrl: ficheUserData.results[0].fiche_user_photoProfilUrl,
                    prenom: ficheUserData.results[0].fiche_user_prenom,
                    userId: ficheUserData.results[0].fiche_user_userId,
                    idFiche: ficheUserData.results[0].id_fiche_user, 
                    newFiche: ficheUserData.results[0].fiche_user_new_fiche
                  }

            }else {
                return ficheUserData.results;
            }
        };

            dispatch(ficheUserActions.getFicheUser(transformeData())); 
        
        

        }catch(error) {
             console.log(error);
        }
    };

};


//requête POST pour envoyer la fiche utilisateur dans la base de données
//création de la table sur fiche_user
export const postFicheUser = (userId, token, newPhotoState, dataUpdateFormData) => {
        //envoyer les nouvelles données dans le serveur
        const formData = new FormData();
        formData.append("image", newPhotoState);  
        formData.append("ficheUser", JSON.stringify(dataUpdateFormData));

        return async (dispatch) => {
                //requête post
                const fetchPostData = async () => {
                  const response = await fetch(
                     `${process.env.REACT_APP_GRAFFITI_API_URL}/api/fiche_user/?userId=${userId}`,
                        {
                         method: "POST",
                         headers: {
                            Authorization: `Bearer ${token}`,
                        },
                         body: formData,
                    }
                );
                const dataResponse = await response.json();

                if(!response.ok) {
                 throw new Error("Impossible d'envoyer la donnée sur le serveur");
                }
                return dataResponse;

              }

              try{
                 await fetchPostData();
                 dispatch(ficheUserActions.postFicheUser(dataUpdateFormData));

              } catch(error){
                console.log(error);
              }
    }
};



//pour effacer le compte de l'utilisateur - ainsi que toutes ses données
export const deleteAccount = (userId, token) => {
    //requête DELETE
    return async (dispatch) => {
        const fetchDeleteData = async () => {

          const response = await fetch(
            `${process.env.REACT_APP_GRAFFITI_API_URL}/api/authentification/delete/?userId=${userId}`,
           {
            method: "DELETE",
            headers: {
               Authorization: `Bearer ${token}`,
             },
            }
          );

          const dataResponse = await response.json();

          if(!response.ok) {
              throw new Error("Impossible de supprimer le compte");
          }
          return dataResponse;
         };

         try {
             await fetchDeleteData();
             dispatch(ficheUserActions.deleteAccount())
         } catch(error) {
             console.log(error);
         }
    };

};


//Requête PUT pour modifier les données de la fiche utilisateur
export const putFicheUser = (userId, token, idFiche, newPhotoState, dataUpdateFormData) => {
   //envoyer les nouvelles données dans le serveur
   const formData = new FormData();
   formData.append("image", newPhotoState);
   formData.append("ficheUser", JSON.stringify(dataUpdateFormData));
   
   return async (dispatch) => {
   //la requête put
   const fetchPutData = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_GRAFFITI_API_URL}/api/fiche_user/${idFiche}/?userId=${userId}`,
            {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }
        );

        const dataResponse = await response.json();

        if(!response.ok){
           throw new Error("Impossible de modifier la fiche");
        }
        return dataResponse;
   };
        
      try {
        await fetchPutData();
        dispatch(ficheUserActions.putFicheUser(dataUpdateFormData));
      }catch(error){
        console.log(error);
      }
   }

};


export const getIdFicheUser = (userId, token) => {
     return async (dispatch) => {
          //requête GET
          const fetchGetData = async () => {
            const response = await fetch(
             `${process.env.REACT_APP_GRAFFITI_API_URL}/api/fiche_user/fiche/?userId=${userId}`,
             {
                 method : "GET",
                 headers: {
                    Authorization : `Bearer ${token} `,
                   
                 }, 
             }
            );
            
            const dataResponse =  await response.json();
 
            if(!response.ok) {
                throw new Error("Problème pour récupérer la fiche user sur le serveur");
            }
 
            return dataResponse;
         };
 
         try {
             const ficheUserData = await fetchGetData();
             const transformeData = () => {
             if(ficheUserData && ficheUserData.results.length > 0){
 
                 return {
                     idFiche: ficheUserData.results[0].id_fiche_user, 
                    }
 
             }else {
                 return ficheUserData.results;
             }
         };
 
             dispatch(ficheUserActions.getIdFiche(transformeData())); 
         
         
 
         }catch(error) {
              console.log(error);
         }
     }

};