import Button from "./Button";
import Card from "./Card";
import classes from "./ConfirmationModal.module.css";
import {createPortal} from "react-dom";



const Backdrop = props => {

   return  <div className={classes.backdrop} onClick={props.onConfirm}/>

};


const ConfirmationModalOverlay = (props) => {
     
   return (
     <section className={classes.modalConfirmation}>
      <Card className={classes.modal}>

          <header className={classes.header}>
              <h2>{props.title}</h2>
          </header>
          
           <div className={classes.content}>
              <p>{props.message}</p>
           </div>
          
           <footer className={classes.actions}>

                <Button 
                   onClick={props.onConfirmDelete} 
                  >
                    Ok
                </Button>
             
                <Button onClick={props.onConfirm}>
                    Annuler
                </Button>
          
           </footer>

      </Card>
     </section>
  
   
     )
};



const ConfirmationModal = (props) => {

    return (
        
     <>
           
           {/**le backdrop**/}
            {createPortal(
              <Backdrop
                onConfirm={props.onConfirm} />,
               document.getElementById("backdrop-root"))}

             {/**la modale**/}
             {
                createPortal(
                 <ConfirmationModalOverlay
                   title={props.title}
                   message={props.message} 
                   onConfirmDelete={props.onConfirmDelete}
                   onConfirm={props.onConfirm}
                   />, 
                 document.getElementById("modal-root")
               )}
</>

    )
}



export default ConfirmationModal;