import { useEffect } from "react";
import classes from "./FicheUser.module.css";
import { Navigate } from "react-router-dom";
import FicheUserDisplay from "../components/FicheUser/FicheUserDisplay";
import {useDispatch, useSelector} from "react-redux";
import {getFicheUser} from "../store/actions/ficheUser-actions";
  


const FicheUser = () => {
     
    const isLoggedIn = useSelector((state) => state.authentification.isLoggedIn);
    const authentification = useSelector((state) => state.authentification.dataResponse);

    const dispatch = useDispatch()


    //exécuté au montage du composant
    useEffect(()=> {
      if(isLoggedIn) {
        dispatch(getFicheUser(authentification.userId, authentification.token));
      }
        
    }, [dispatch, isLoggedIn, authentification.token, authentification.userId]);

     
 
       return(
        <section className={classes.ficheUser}>
          
          {!isLoggedIn && <Navigate to="/" replace={true}/>} 
        
        
          {isLoggedIn && 
            (
                <FicheUserDisplay/>
                  
               )}
 
        </section>
       )
}



export default FicheUser;



 