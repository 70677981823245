import { useEffect, useMemo, useState } from 'react';
import { Navigate, useParams } from "react-router-dom";
import emptyPortait from "../../assets/images/photo-avatar.png";
import classes from "./FicheUserDisplayRead.module.css";
import useHttp from '../../hooks/use-http';
import { useSelector } from 'react-redux';
import AllPostsOfOneUser from './AllPostsOfOneUser';
  

const FicheUserDisplayRead = () => {
   
     const { id } = useParams();
       
     const [data, setData] = useState();

  
     const isLoggedIn = useSelector((state) => state.authentification.isLoggedIn);

     const authentification = useSelector((state) => state.authentification.dataResponse);
    
     const {sendRequest: fetchGetFicheUserHandler} = useHttp();
     
    

  //requête GET pour récupérer la fiche utilisateur
 // pour qu'elle puisse êtee visible aux autres utilisateurs
const requestConfig = useMemo(() => ({ 
      url: `${process.env.REACT_APP_GRAFFITI_API_URL}/api/fiche_user/fiche/${id}?userId=${authentification.userId}`, 
         method: "GET",
         headers: {
            Authorization : `Bearer ${authentification.token}`, 
      }
     }), [authentification.token, authentification.userId, id]);
    
  
 useEffect(() => {
   
   id &&  fetchGetFicheUserHandler(requestConfig, (dataResponse) => {
         setData(dataResponse);
         console.log(dataResponse);
         
   });

 }, [ id, fetchGetFicheUserHandler, requestConfig]);
  
  
  return (
  <>
   {!isLoggedIn && <Navigate to="/" replace={true}/>}
  
  
  {isLoggedIn && ( 
    
    
   <div className={classes.container}>
   <section className={classes.user}>
   
    <h1>
       
         {data && data[0].fiche_user_prenom} {/*     */}
       
        
    </h1>

      
   {/**photo de profile par défaut**/}
    <p> 
      <img src={data && data[0].fiche_user_photoProfilUrl ?
                 data && data[0].fiche_user_photoProfilUrl : 
                 emptyPortait} alt="photo_fiche"
                 crossOrigin="anonymous"
                 />
    </p>
    
   
     
      
    <p className={classes.bio}>{data && data[0].fiche_user_bio}</p>

        
   
</section>

 
 
   
 </div>
 
   )} 
   
   <section>
     {/**Il faudra mettre un composant contenant tous les posts de l'utilisateur -
   *  des plus récents aux plus anciens / le composant se nommera <AllPostsOfOneUser/>*/}
     <AllPostsOfOneUser/>
    </section>  
  
</>

  )
}

export default FicheUserDisplayRead;