import React, { useCallback, useEffect, useState } from 'react';
import classes from "./FeedIdentifierCreatorComment.module.css";
import dateFormat from "dateformat";



const FeedIdentifierCreatorComment = ({
    token, 
    userIdComment, 
    userIdToken,
    dateComment
  }) => {

  //modification du format de la date 
  const date = dateFormat(dateComment, "isoDate");
  const time = dateFormat(dateComment, "isoTime");


  const [ficheUser, setFicheUser] = useState(null);

  
  const fetchGetFicheUserHandler = useCallback(async () => {
  
  //récupérer dans la base de données la photo de l'utilisateur qui vient d'effectuer un commentaire
  const url = `${process.env.REACT_APP_GRAFFITI_API_URL}/api/fiche_user/fiche/${userIdComment}?userId=${userIdToken}`;

      try {

          const response = await fetch(url, {
                  method: "GET",
                  headers: {
                     Authorization: `Bearer ${token}`,
                  },
               }
          );

          const dataResponse = await response.json();

            if(response.ok) {
               console.log(dataResponse);
               setFicheUser(dataResponse.results);
            } else {
              console.log(dataResponse);
              throw new Error(dataResponse.error);
            }



      } catch(error) {
         console.log(error);
      }
   
  }, [token, userIdComment, userIdToken]);


   useEffect(() => {
       fetchGetFicheUserHandler();
   }, [fetchGetFicheUserHandler]);

  


  return (
      <> 
        { 
          ficheUser && ( 
          <div className={classes.feedIdentifierCreatorComment}>
              <p 
                className={classes.feedIdentifierCreatorCommentPrenom}>
                {ficheUser[0].fiche_user_prenom}
                </p>
              
              <p 
                className={classes.feedIdentifierCreatorCommentDate}>
                {`${date}-${time}`}
              </p>
          </div>
       )}
      </>
  )
}

export default FeedIdentifierCreatorComment;
