import Button from "./Button";
import Card from "./Card";
import classes from "./ErrorModal.module.css";
import {createPortal} from "react-dom";



const Backdrop = props => {

   return  <div className={classes.backdrop} onClick={props.onConfirm}/>

};


const ErrorModalOverlay = (props) => {
     
   return (
        
      <Card className={classes.modal}>

          <header className={classes.header}>
              <h2>{props.title}</h2>
          </header>
          
           <div className={classes.content}>
              <p>{props.message}</p>
           </div>
          
           <footer className={classes.actions}>
              <Button onClick={props.onConfirm}>
                OK
              </Button>
           </footer>

      </Card>
  
   
     )
};



const ErrorModal = (props) => {

    return (
        
     <>
           
           {/**le backdrop**/}
            {createPortal(
              <Backdrop
                onConfirm={props.onConfirm} />,
               document.getElementById("backdrop-root"))}

             {/**la modale**/}
             {
                createPortal(
                 <ErrorModalOverlay
                   title={props.title}
                   message={props.message}
                   onConfirm={props.onConfirm}
                   />, 
                 document.getElementById("modal-root")
               )}
</>

    )
}



export default ErrorModal;