import React from 'react';
import classes from "./FeedIdentifierCreatorForSearchResult.module.css";
 


const FeedIdentifierCreatorForSearchResult = ({user_prenom }) => {

   
   

  return (
    <div className={classes.feedIdentifierCreator}>
         <p>{user_prenom}</p>
        
    </div>
  )
}

export default FeedIdentifierCreatorForSearchResult;
