




const Wrapper = props => {

   return  props.children;

}



export default Wrapper;