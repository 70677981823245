import React, { useState } from 'react';
import classes from "./FeedButtonComment.module.css";
import Button from "../../UI/Button";
import ConfirmationModal from "../../UI/ConfirmationModal";
import { useDispatch, useSelector } from 'react-redux';
import { deleteFetchCommentary } from '../../../store/actions/commentary-actions';
import { commentaryActions } from '../../../store/slices/commentary-slice';
 
const FeedButtonComment = ({
        userIdToken,
        userIdComment,
        idCommentUser, 
        token, 
        
      }) => {

        const [confimationModal, setConfimationModal] = useState(null);

       
       const authentification = useSelector((state) => state.authentification.dataResponse);

        const dispatch = useDispatch();

        const isUpdatingComment = useSelector((state) => state.commentary.modificationComment);
 

        
        //boutton envoyer servant à valider la modification du commentaire
        const buttonSendHandler = () => {
             dispatch(commentaryActions.buttonSend(true));
        };

 
      
    
        //la requête à envoyer au backend pour supprimer un commentaire dans le feed
     const deleteCommentHandler = () => {
       dispatch(deleteFetchCommentary(idCommentUser, userIdToken, token));
       setConfimationModal(null);
     }
   


    
    //modale de confirmation pour supprimer le commentaire
    const confimationModalHandler = () => {
      setConfimationModal({
        title: "Confirmez la suppression du commentaire",
        message: "Cette suppression sera définitive"

      });
    };


    const modificationHandler = () => {
       dispatch(commentaryActions.modificationComment({
            isUpdating: true,
            commentToEdit: idCommentUser,
          }));
           
    };

    //gestion du bouton envoyer
    const modificationOneComment = 
        (idCommentUser === 
        isUpdatingComment.commentToEdit) && 
        isUpdatingComment.isUpdating;



  return (
    <div className={classes.feedButtonComment}>
       {/*<Button>Répondre</Button> */}
      
       
          <>   
         
          {/**bouton modifier */}
            {userIdToken === 
             userIdComment && 
             !isUpdatingComment.isUpdating && 
              <div className={classes.modifyComment}> 
                <Button id={idCommentUser} 
                    onClick={modificationHandler}>
                    Modifier
                </Button>
             </div>
             }
             

            {/***Bouton envoyer */}
            {userIdToken === 
             userIdComment &&   
             modificationOneComment &&
             <div className={classes.sendComment}>
               <Button 
                  id={idCommentUser} 
                  onClick={buttonSendHandler}>
                  Envoyer
               </Button>
              </div>}

            {/**bouton supprimer**/}
            {userIdToken === 
             userIdComment && 
             !modificationOneComment &&
              <div className={classes.deleteComment}> 
                <Button onClick={confimationModalHandler}>
                  Supprimer
                 </Button>
              </div>}

            
            {(userIdToken === 
             userIdComment || 
             authentification.admin === 1) && 
             confimationModal && 
              <ConfirmationModal 
                title={confimationModal.title}
                message={confimationModal.message}
                onConfirm={()=>setConfimationModal(null)}
                onConfirmDelete={ deleteCommentHandler}
               />
               }

          </>
        
    </div>
  )
  }

export default FeedButtonComment;
