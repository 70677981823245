import { useCallback, useState } from "react";
 

//CUSTOM HOOK HTTP: GET - POST - DELETE - PUT
const useHttp =  () => {

    const [isLoading, setIsLoading] = useState(null);
    const [error, setError] = useState(null);

       
 
    const sendRequest = useCallback(async(requestConfig, applyData) => {

        setIsLoading(true);
        setError(null);

        //pour contrôler la présence du constructor  FormData
        const controlFormData = requestConfig.body instanceof FormData;


        //afin d'éviter d'avoir le warning: can't perform React state update unmounted component
        let isActive = false;          
        
 
        try {
            
         const url = requestConfig.url;


            const response = await fetch(url, {
                method: requestConfig.method,
                headers: requestConfig.headers,
                body: requestConfig.body && controlFormData
                     ? requestConfig.body
                     : requestConfig.body 
                     ? JSON.stringify(requestConfig.body) 
                     : null, 
                    
            },
             
 
 
         ); 

        const dataResponse = await response.json();
        console.log(response);

        if(response.ok) {
             applyData(dataResponse.results);
             console.log("je suis dans use-http");

         } else {
           console.log(dataResponse); 
           isActive = true;
           setError(
             dataResponse);
          
          }

        }catch(error) { 

           console.log(error);
           
        } finally {
            if(isActive) {
                 setError(null);
                 setIsLoading(false);
            };
           
        }

 
    },[]);

   
    return {
        sendRequest,
        isLoading,
        error
    
    };

};


export default useHttp;