import {useEffect, useRef, useState} from "react";
import classes from "./FicheUserDisplay.module.css";
import Button from "../UI/Button";
import emptyPortait from "../../assets/images/photo-avatar.png";
import ConfirmationModal from "../UI/ConfirmationModal";
import ErrorModal from "../UI/ErrorModal";
import {useDispatch, useSelector} from "react-redux";
import { deleteAccount, getFicheUser, getIdFicheUser, postFicheUser, putFicheUser } from "../../store/actions/ficheUser-actions";
import { useNavigate } from "react-router-dom";
import { authentificationActions } from "../../store/slices/authentification-slice";
import { ficheUserActions } from "../../store/slices/ficheUser-slice";
 

const FicheUserDisplay = () => {
 

     const dispatch = useDispatch();
    
     const [confimationModal, setConfimationModal] = useState(null);
     const [imgPrevisualization, setImgPrevisualization] = useState(null);
     const [newPhotoState, setNewPhotoState] = useState({});

     const [enterNameIsValid, setEnterNameIsValid] = useState(false);
     const [enterFirstNameIsValid, setEnterFirstNameIsValid] = useState(false);
     const [enterBioIsValid, setEnterBioIsValid] = useState(false);

   
   const [untouchedName, setUntouchedName] = useState(true);
  
   const [untouchedBio, setUntouchedBio] = useState(true);
   
   const [displayButtonSend, setDisplayButtonSend] = useState(false);
  
   const [error, setError] = useState(null);
   
   const navigate = useNavigate();

  const prenomInputRef = useRef();
  const bioInputRef = useRef();
 
 
  const authentification = useSelector((state) => state.authentification.dataResponse);
 
  const modification = useSelector((state) => state.ficheUser.modification);

  //pour fermer la modale de suppression du compte
  const deleteFicheUserStore = useSelector((state) => state.ficheUser.confimationModal);


  const data = useSelector((state) => state.ficheUser.ficheUserData);
  const [dataUpdate, setDataUpdate] = useState(data); 
  const [dataUpdateFormData, setDataUpdateFormData] = useState({});


  const presenceIdFiche = useSelector((state) => state.ficheUser.presenceIdFiche.idFiche);
 
    

   
   //pour fermer la modal servant à confirmer ou non la supprssion du compte
   useEffect(() => {
      setConfimationModal(false);
   }, [deleteFicheUserStore]);



   //pour modifier les données se trouvant sur la page fiche utilisateur
   const modificationHandler = () => {
       dispatch(ficheUserActions.isModification(true));

       //pour récupérer l'id de la fiche lorsque l'utilisateur veut modifier sa fiche juste après sa création
       //il n'y a pas d'id de fiche dans le store redux
       dispatch(getIdFicheUser( authentification.userId, authentification.token));
   };


//----------------------profilPhotoHandler----------------------------------------
//pour changer de photo de profil
const photoProfilHandler = (event) => {
  //la gestion de la nouvelle photo
let newPhoto;
if( event.target.files && event.target.files.length === 1) {
    newPhoto = event.target.files[0];

   //Prévisualisation de la nouvelle photo de profil
   const reader = new FileReader();
   reader.onload = (event) => {
          setImgPrevisualization(event.target.result);
   };
    reader.readAsDataURL(newPhoto);

}

   setNewPhotoState(newPhoto);
};



//----------------------changeHandler-----------------------------------
   //pour surveiller les modifications effectuées dans les champs
   const changeHandler = (event) => {
      
       
       const enteredPrenomBrut = prenomInputRef.current.value;
       const enteredPrenom = enteredPrenomBrut.charAt(0).toUpperCase() + enteredPrenomBrut.slice(1).toLowerCase();
      
      
       const enteredBio = bioInputRef.current.value;

        

       //mettre à jour le state
       setDataUpdate(prevState => ({
           ...prevState.dataUpdate,
           prenom: enteredPrenom,
           bio: enteredBio
        }));


       //création de l'objet avec les propriétés à envoyer dans formData
       const dataUpdateFormData = {
            prenom: enteredPrenom,
            bio: enteredBio,
            newFiche: true,
            userId: authentification.userId,
       };


         setDataUpdateFormData(dataUpdateFormData);
      
   };


   //Suppression de la fiche de l'utilisateur  et de toutes les données de celui-ci
   const deleteAccountHandler = () => {
 
     dispatch(deleteAccount(authentification.userId, authentification.token));
     dispatch(authentificationActions.logout());
        
      };
    
   
         

//---------------------sendHandler------------------------------
   //au click sur le bouton ENVOYER du formulaire
   const sendHandler = () => {
         

        //pour savoir si un objet est vide ou non
        const dataUpdateFormDataIsEmpty = Object.keys(dataUpdateFormData).length === 0 
                                          && dataUpdateFormData.constructor === Object;

       

        //contrôler la validation du formulaire
        //contrôle de la validité des input(ils ne doivent pas être vides)
       function controlInputEmpty(inputValue, setState) {
             if(!dataUpdateFormDataIsEmpty && inputValue.trim() === "") {
                 setState(false);
              
              return; 
             }
            setState(true);
        };

         controlInputEmpty(dataUpdateFormData.prenom, setEnterFirstNameIsValid );
         controlInputEmpty(dataUpdateFormData.bio, setEnterBioIsValid);


 
        //la donnée pour la requête du serveur
        dispatch(postFicheUser(
           authentification.userId, 
           authentification.token, 
           newPhotoState, 
           dataUpdateFormData));
  
           //pour rester sur la fiche utilisateur après que celle-ci a été créé
           //(pas de redirection vers la page accueil) 
          // navigate("fiche_utilisateur");
          navigate("user");

  };

   
   //savoir si le champ et vide ou non lorsque je quitte l'input
   const blurHandler = (event) => {
        
     function warningBlur(setEnterValueIsValid, setUntouchedValue) {
             if(event.target.value.trim() === "") {
                   setEnterValueIsValid(false);
                   setUntouchedValue(false);
             }else {
                   setEnterValueIsValid(true);
                   setUntouchedValue(true);
             }
        }

       event.target.id === "lastName" && warningBlur(setEnterNameIsValid, setUntouchedName);
       event.target.id === "bio" && warningBlur(setEnterBioIsValid, setUntouchedBio);


   };


   //modale de confirmation pour supprimer le compte
   const confimationModalHandler = () => {
    setConfimationModal({
      title: "Confirmez la suppression du compte",
      message: "Cette suppression sera définitive"

    });
   };


   //Condition pour changer la classe de l"input si celui-ci n'est pas valide
   function inputClasses(enterValueIsValid, untouchedValue) {
    const nameInputClasses = (enterValueIsValid || 
      untouchedValue) ?
      classes["form-control"] : 
      `${classes["form-control"]} ${classes.invalid}` ;

       return nameInputClasses;
   };

   const nameInputClasses =  inputClasses(enterNameIsValid, untouchedName);
   const bioInputClasses =  inputClasses(enterBioIsValid, untouchedBio);


//-------------------Gestion des onBlur des inputs------------
//3 cas à gérer(concernant le bouuton envoyer) -
const untouch = untouchedName && 
 untouchedBio;
useEffect(() => {
   if(data.bio && 
      data.prenom && 
      untouch) {
      setDisplayButtonSend(true); 

   }else if(enterNameIsValid && 
            enterBioIsValid &&
            untouch
            ){
      setDisplayButtonSend(true);
}else {
      setDisplayButtonSend(false);
}
  
},[
    data.bio,
    data.prenom, 
    enterBioIsValid, 
    enterNameIsValid, 
    untouch,
    ]);
 
 

   //Envoie de fiche utilisateur venant d'être modifiée
   const sendPutHandler = () => { 
    //contrôler si les champs du formulaire ont été modifié
      const dataUpdateFormDataEmpty = Object.keys(dataUpdateFormData).length === 0;

      //contrôler si la photo a été modifié
      const newPhotoStateEmpty = Object.keys(newPhotoState).length === 0 && newPhotoState.constructor === Object;

      //l'utilisateur n'a modifié aucun champ de la fiche
      const emptyObjectControl = dataUpdateFormDataEmpty && newPhotoStateEmpty;


      if(emptyObjectControl) {
         dispatch(ficheUserActions.isModification(false));
      } else {
        //requête putFicheUser prioritaire à getFicheUser
        //pour récupérer la nouvelle URL de la photo  fournit par  le backend
        //et la mettre dans le state et écraser l'ancienne URL de la photo
        Promise.resolve(
            dispatch(putFicheUser(
              authentification.userId, 
              authentification.token,
              presenceIdFiche,
              newPhotoState,
              dataUpdateFormDataEmpty ?
              data :
              dataUpdateFormData,
              )
            )
           ).then(() =>
             dispatch(getFicheUser(authentification.userId, authentification.token))
             ).catch((error) => {console.log(error)});
      }
   };

   //pour afficher le bouton Envoyer Post(à la création de la fiche)
   //ou bien Envoyer PUT(à la modification de la fiche)
   const conditionButtonSend = data.length === 0;
    
    
    return (
        
<div className={classes.container}>
    
     <section className={classes.user}>
        
        <h2>Votre profil:</h2>

          <h1>{ dataUpdate.prenom}</h1>


          {/**Affichage des données utilisateur* */}

          {!modification && (
          <>
              {imgPrevisualization ? 
               <img src={imgPrevisualization} alt="prévisualisation" crossOrigin="anonymous"/>
               :
                <img 
                   src={data.photoProfilUrl ?
                       data.photoProfilUrl : 
                       emptyPortait}
                   alt="photo_fiche" 
                   crossOrigin="anonymous"
                        />
                        }

            
            
             
            
              {dataUpdate.bio ? <p className={classes.bio}>{dataUpdate.bio}</p> : ""}
        </>)}



  {/*********************Modifier les données de la fiche utilisateur*******************************/} 
            
         {modification && (
           <>
             <form>
               {error && <ErrorModal
                 title={error.title}
                 message={error.message}
                 onConfirm={() =>setError(false)}
                   />}
             <div className={classes["group-control"]}>
             <p> 
               {!imgPrevisualization ? 
                  <img 
                     src={data.photoProfilUrl ? data.photoProfilUrl : emptyPortait}
                     alt="photo_fiche"
                     crossOrigin="anonymous"
                    />
                 :
                  <img 
                     src={imgPrevisualization} 
                     alt="photo_profil_prev"
                     crossOrigin="anonymous"
                     /> }
                    </p> 
                   
                   <label for="photo"> 
                    <span>Choisir une photo</span>
                    <input 
                      className={classes.inputFile}
                     id="photo"
                      type="file" 
                      accept=".jpeg,.jpg, .png" 
                      onChange={photoProfilHandler}
                      /> 
                  </label>

                  <div className={nameInputClasses}>

                    <label htmlFor="lastName">Nom d'utilisateur:</label>
                    <input type="text" 
                      defaultValue={ dataUpdate.prenom} 
                      onChange={changeHandler} 
                      onBlur={blurHandler}
                      ref={prenomInputRef}
                      placeholder="Entrez votre nom d'utilisateur"
                      id="lastName"
                        />
                    {!enterNameIsValid && 
                     !untouchedName &&
                     <p className={classes["error-text"]}>
                        Ce champ doit être rempli
                     </p>}
                  </div>



                

                   <div className={bioInputClasses}> 
                    <label htmlFor="bio">bio: </label>
                    <textarea type="text"
                      defaultValue={dataUpdate.bio}
                      onChange={changeHandler}                      
                      onBlur={blurHandler}
                      ref={bioInputRef} 
                      placeholder="Votre bio"
                      id="bio"
                    />                     
                   {!enterBioIsValid && 
                    !untouchedBio &&
                    <p className={classes["error-text"]}>
                       Ce champ doit être rempli
                    </p>}
                  </div>
               
               
                </div>
              </form>

           </>
         )
        }




            

    

{/******************************************************************************************** */}

         <div> 
          
          {/**bouton modifier la fiche */}
           {!modification && <Button onClick={modificationHandler}>
               Modifier mon profil
           </Button>}


            {/**bouton envoyer la fiche - a la creation de celle-ci */}
            {modification && 
              displayButtonSend && 
              !conditionButtonSend &&
              <Button onClick={sendPutHandler}>
                  Envoyer {/**PUT**/}
              </Button>}

           {/**bouton envoyer la fiche - a la creation de celle-ci */}
           {modification && 
           displayButtonSend &&
           conditionButtonSend &&
           <Button onClick={sendHandler}>
               Envoyer
           </Button>}

           {modification && 
            !displayButtonSend &&
           <Button style={{background: "grey"}}>
               Envoyer
           </Button>}
           
           
           {/**modale de confirmation pour la suppresion du compte */}
           {
             confimationModal && 
             <ConfirmationModal
                title={confimationModal.title} 
                message={confimationModal.message}
                onConfirm={() => setConfimationModal(null)}
                onConfirmDelete={() => deleteAccountHandler()}
              /> }
              <Button onClick={confimationModalHandler}>
                Supprimer mon compte
              </Button>
            

        </div>

        
         
     </section>
    
     

   </div>
   )
 }
 
 export default FicheUserDisplay;
 