import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import classes from "./FeedDisplayComment.module.css";
import FeedCommentPhotoUser from './FeedCommentPhotoUser';
import FeedIdentifierCreatorComment from './FeedIdentifierCreatorComment';
import FeedComment from './FeedComment';
import Card from "../../UI/Card";
import FeedButtonComment from './FeedButtonComment';
import {useSelector, useDispatch} from "react-redux";
import { getFetchCommentary } from '../../../store/actions/commentary-actions';
 


const FeedDisplayComment = ({
    onUpdate, 
    token, 
    userIdToken,
    idCommentButton,
    isDisplayedComment,
   }) => {

 
 
  const comments = useSelector((state) => state.commentary.comments);
  const newComment = useSelector((state) => state.commentary.onNewComment);
  
 
  console.log(newComment);
 

  const dispatch = useDispatch();


 

  //pour aller chercher les commentaires sur la base de données
  useEffect(() => {

    //aller chercher tous les commentaires de la base de données se trouvant dans la table comment_suser
    dispatch(getFetchCommentary(idCommentButton, userIdToken, token)); 

  }, [onUpdate, 
      newComment, 
      idCommentButton, 
      token,
      userIdToken,
      dispatch
    ]);
  

  
//Gestion de l'affichage des commentaires au click sur le bouton commentaires des posts




return (
  <section className={classes.feedDisplayComment}>
    {/**Afficher les commentaires sous le post si il y a des commentaires */}

     {isDisplayedComment &&
      comments && 
      comments.map((comment) => (
          /**Affichage conditionnel des commentaires -
           *  au click sur le bouton commentaires 
           * et que l'on se trouve sur le bon post */
        comment.comments_user_id_posts === 
        idCommentButton &&
        <Card 
           className={classes.card}  
           key={comment.id_comments_user}>

         <div> 
          <Link 
               //to={`/fiche_utilisateur/read/${comment.comments_user_userId}`}
               to={`/user/read/${comment.comments_user_userId}`} 

               > 
              <FeedIdentifierCreatorComment
                  token={token}
                  userIdToken={userIdToken}
                  userIdComment={comment.comments_user_userId}
                  dateComment= {comment.comments_user_date}
                  />
            </Link> 
           
        
           <div className={classes.photoComment}>
            <Link
              //to={`/fiche_utilisateur/read/${comment.comments_user_userId}`}
              to={`/user/read/${comment.comments_user_userId}`} 

               > 
                <FeedCommentPhotoUser 
                  token={token}
                  userIdToken={userIdToken}
                  userIdComment={comment.comments_user_userId}
                  />
            </Link>
          
           
           
            <FeedComment
               comment={comment.comments_user_message}
               idComment={comment.id_comments_user}
               userId={comment.comments_user_userId}
               token={token}
               userIdToken={userIdToken}
               idPost={comment.comments_user_id_posts}
                
              />
           </div>


           <FeedButtonComment
             token={token}
             idCommentUser={comment.id_comments_user}
             userIdToken={userIdToken}
             userIdComment={comment.comments_user_userId}
            />
          
       </div>

     </Card>
      ))
    }
      
  </section>

)
}

export default FeedDisplayComment;