import { useState } from "react";
import FeedDisplayPost from "./FeedDisplayPost";
//import FeedNewPost from "./FeedNewPost";
import classes from "./Feed.module.css";



const Feed = () => {


  const [message, setMessage] = useState(null);

  //remonter l'information de FeedNewPost et la transmettre pour qu'un message 
  //a bien été envoyé dans la base de données
  //pour pouvoir exécuter un re-render dans le composant FeedDisplayPost
    const onUpdate = (message) => {

      const messageUpdate = message;

      setMessage(messageUpdate);
 
    };



    return (
        <div className={classes.feed}>
          {/*<FeedNewPost onUpdate={onUpdate} />*/}
          <FeedDisplayPost onUpdate={message} />
        </div>
    )
}



export default Feed;