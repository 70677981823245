import React, { useCallback, useEffect, useState } from 'react';
import classes from "./FeedBadge.module.css";
import { useSelector } from 'react-redux';


const FeedBadge = ({
    idPostsUser, 
    token, 
    userIdToken, 
    
  }) => {

  const [numberOfComments, setNumberOfComments] = useState([]);

   const newComment = useSelector((state) => state.commentary.onNewComment);
   const updateDeleteComment = useSelector((state) => state.commentary.onUpdateDeleteComment);
   

const fetchGetNumberCommentHandler = useCallback(async () => {
  
//requête pour avoir le nombre de commentaires(les commentaires du post selectionné)
//aller chercher tous les commentaires de la base de données se trouvant dans la table comments_user
const url = `${process.env.REACT_APP_GRAFFITI_API_URL}/api/posts/comments/${idPostsUser}?userId=${userIdToken}`;
   
try {

        const response = await fetch(url, {
                method: "GET",
                headers: {
                   Authorization: `Bearer ${token}`,
                },
             }
        );

        const dataResponse = await response.json();

          if(response.ok) {
             console.log(dataResponse);
             setNumberOfComments(dataResponse.results);
          } else {
            console.log(dataResponse);
            throw new Error(dataResponse.error);
          }



    } catch(error) {
       console.log(error);
    }

}, [idPostsUser, token, userIdToken]);  

useEffect(() => {
   fetchGetNumberCommentHandler();
},[
  newComment, 
  updateDeleteComment, 
  fetchGetNumberCommentHandler]);



  return (
    <div className={classes.feedBadge}>
        <div className={classes.numberCircle}>
          {numberOfComments && numberOfComments.length} {/* OU alors: {numberOfComments?.length}*/}
        </div>
    </div>
  )
}

export default FeedBadge;
