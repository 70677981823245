import React from 'react';
import classes from "./FeedImagePost.module.css";
 


const FeedImagePost = ({photoPost}) => {
  
   
  return (
    <section className={classes.feedImagePost}>
        {photoPost && ( 
          <a href={photoPost} rel="noreferrer" target="_blank">
            <img  
              crossOrigin="anonymous"  
              src={photoPost} 
              alt="lien_de_la_photo"
               />   
           </a>
          )}
    </section>
  )
}

export default FeedImagePost;
