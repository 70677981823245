import React, { useEffect, useState } from 'react';
import classes from "./FeedComment.module.css";
import Linkify from "linkify-react";
import {useDispatch, useSelector} from "react-redux";
import { putFetchCommentary } from '../../../store/actions/commentary-actions';



const FeedComment = ({ 
   comment,
   idComment,
   token,
   userIdToken,
  
}) => {


    const [messageTextarea, setMessageTextarea] = useState({
      comments_user_message : comment,
  });

  const dispatch = useDispatch();

  const buttonSend = useSelector((state) => state.commentary.modificationComment.buttonSend);
  const isUpdatingComment = useSelector((state) => state.commentary.modificationComment);
   

  const commentToEdit = isUpdatingComment.commentToEdit;

  //condition pour afficher l'interface de modification d'un post
  const modificationOneComment = (commentToEdit === idComment) && isUpdatingComment.isUpdating;


  //fonction éxécuté par onChange du textArea du post
  const messageModificationHandler = (event) => {
        
      setMessageTextarea({"comments_user_message": event.target.value});
  };

 

 
//requête PUT pour envoyer les données au serveur
 useEffect(() => {
   
//envoyer le commentaire qui a été mis à jour
if(buttonSend && modificationOneComment ) {
    dispatch(putFetchCommentary(idComment, userIdToken, token, messageTextarea));
 };

 }, [buttonSend,
      modificationOneComment, 
      idComment, 
      messageTextarea, 
      token, 
      userIdToken, 
       dispatch]);
  



  return (  
    <div className={classes.feedComment}>
     {
        modificationOneComment ?  ( 
             
             <textarea 
                defaultValue={comment}
                onChange={messageModificationHandler}
                />
            
            )
             
            :( 
              
                 <Linkify options={{target: "_blank"}}> 
                  <p className={classes.commentary}>
                     {comment} 
                  </p>
                 </Linkify>
              
            )
        }
    </div>
  )
};


export default FeedComment;