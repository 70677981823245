import React from 'react';
import classes from "./LogoToFeedPost.module.css";
import socialMedia from "../../assets/images/Profile_picture_social_media_2.png";


const LogoToFeedPost = () => {
  return (
    <div className={classes.logoToFeedPost}>
       <img crossOrigin="anonymous" 
        src={socialMedia}  alt="social_media"/>
    </div>
  )
}

export default LogoToFeedPost;
